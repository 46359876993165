import React, { useState } from 'react';
import { db } from './firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
// import { httpsCallable } from 'firebase/functions';
import './App.css';
import logo from './assets/images/logo-transparent.png';
//import collaborateImage from './assets/images/collaborate.svg';
import webAppImage from './assets/images/web-app.svg';
import fullstackImage from './assets/images/fullstack.svg';
import mobileAppImage from './assets/images/mobile-app.svg';
import cloudOptimizationImage from './assets/images/collaborate.png'; // New image for cloud services

function App() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = {
      name,
      email,
      message,
      timestamp: serverTimestamp(),
    };

    try {
      await addDoc(collection(db, 'contacts'), formData);
      // await sendEmail({ name, email, message });

      setToastMessage('Message sent successfully!');
      setShowToast(true);
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('Error submitting message:', error);
      setToastMessage('Failed to send message. Please try again.');
      setShowToast(true);
    } finally {
      setIsLoading(false);
    }

    setTimeout(() => setShowToast(false), 3000);
  };

  return (
    <div className="app">
      {/* Header Section */}
      <header className="header">
        <div className="header-content">
          <img src={logo} alt="SultanSoft Logo" className="logo" />
          <ul className="navbar">
            <li><a href="#about">About Us</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#what-we-do">What We Do</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
          <button className="cta-btn" onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}>
            Get a Free Consultation
          </button>
        </div>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <h2>Building the Future with Cutting-Edge Technology</h2>
        <p>At SultanSoft, we help businesses reduce infrastructure costs, improve service performance, and build future-ready solutions.</p>
        <button onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}>
          Contact Us
        </button>
      </section>

      {/* About Section */}
      <section className="about" id="about">
        <h2>About Us</h2>
        <p>SultanSoft is a cutting-edge software solutions provider, leveraging artificial intelligence and breakthrough technologies to deliver seamless solutions. We work with businesses to optimize their cloud infrastructure, reduce operational costs, and enhance service efficiency.</p>
      </section>

      {/* What We Do Section */}
      <section className="what-we-do" id="what-we-do">
        <h2>What We Do</h2>
        <p>At SultanSoft, we specialize in software development, cloud optimization, and business transformation through technology. Our goal is to make your services faster, more efficient, and scalable.</p>
        <img src={cloudOptimizationImage} alt="what we do" />
      </section>

      {/* Services Section */}
      <section className="what-we-do" id="services">
        <h2>Services We Offer</h2>
        <div className="services">
        <div className="service-item">
          <img src={webAppImage} alt="Website Development" />
          <h3>Website Development</h3>
          <p>We build professional websites and landing pages using the latest web technologies.</p>
        </div>
        <div className="service-item">
          <img src={fullstackImage} alt="Full-Stack Applications" />
          <h3>Full-Stack Applications</h3>
          <p>We deliver end-to-end full-stack solutions, including APIs and custom business applications.</p>
        </div>
        <div className="service-item">
          <img src={mobileAppImage} alt="Mobile Applications" />
          <h3>Mobile Applications</h3>
          <p>Custom mobile applications for Android and iOS, designed to give your customers seamless access to your services.</p>
        </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="contact">
        <h2>Contact Us</h2>
        <p>Have a question or need a consultation? Reach out to us and experience how SultanSoft's solutions can transform your business.</p>
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Your Name"
              required
              disabled={isLoading}
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your Email"
              required
              disabled={isLoading}
            />
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Your Message"
              rows="5"
              required
              disabled={isLoading}
            />
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Sending...' : 'Submit'}
            </button>
          </form>
          {isLoading && <div className="loading-spinner">Loading...</div>}
        </div>
        {showToast && (
          <div className="toast-message">
            {toastMessage}
          </div>
        )}
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; 2024 SultanSoft. All rights reserved.</p>
        <p>Opp Ado Bayero Mall, Kano, Nigeria</p>
      </footer>
    </div>
  );
}

export default App;
